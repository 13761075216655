

body {
  margin: 0;
  background-color: #000000 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home_button {
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  width: 70%;
  -webkit-border-radius: 13;
  -moz-border-radius: 13;
  border-radius: 13px;
  -webkit-box-shadow: 0px 1px 3px #666666;
  -moz-box-shadow: 0px 1px 3px #666666;
  box-shadow: 0px 1px 3px #666666;
  font-family: Arial;
  color: #ffffff;
  font-size: 20px;
  padding: 20px 20px 20px 20px;
  text-decoration: none;
}

.light_blue_button.home_button  {
  background: #0099cb;
  border: solid #1f628d 2px;
}

.light_blue_button.home_button a, .dark_blue_button.home_button a {
  color: #fff
}

.dark_blue_button.home_button  {
  background: #210057;
  border: solid #210057a3  2px;
}


.light_blue_button.home_button:hover {
  background: #3cb0fd;
  text-decoration: none;
}

.dark_blue_button.home_button:hover {
  background: #2a0e5c;
  text-decoration: none;
}

.yellow_button.home_button  {
  background: #fffd10;
  border: solid #a9a900 2px;
  color: #000000 !important;
}

.yellow_button.home_button:hover  {
  background: #a9a900;
  text-decoration: none;
  color: #000000 !important;
}

a {
  text-decoration: none !important;
}

.nav-link {
  color: #fff !important
}

.nav-link:hover {
color: #0099cb !important
}